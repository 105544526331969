:root {
  --accent-color: gold;
  --selected-color: #202123;
  
}



.hstackMimic {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0px 0px 0px 0px;
  padding: 0px;
}

.hidden {
  display: none;
}

.primaryButtonBox {
  background-color: var(--accent-color);;
  border-radius: 10px;
  margin: 0px 0px 0px 0px;
  width: calc(100% - 30px);
  box-sizing: content-box;
}

.primaryButton {
  font-weight: 500;
  font-size: 16px;
  padding: 15px;
  color: white;
  border: 0;
  box-sizing: border-box;
}

.verification-container {
  display: flex;
  justify-content: flex-start;
}

.verification-container input {
  padding: 0;
  text-decoration: none;
  margin: 0px 10px 0px 0px;
  width: 10vw;
  max-width: 40px;
  max-height: 40px;
  height: 10vw;
  text-align: center;
  font-size: 22px;
  border: 2px solid transparent;
  background-color: #f5f5f7;
  border-radius: 8px;
  outline: none;
}

.verification-container input:focus {
  border-color: var(--accent-color);;
}

.loading-indicator {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 3px solid rgba(255, 255, 255, 0.3); /* Light circle */
  border-radius: 50%;
  border-top: 3px solid var(--accent-color); /* Dark circle portion */
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.errorText {
  font-size: 12px;
  font-weight: 400;
  color: red;
  text-align: left;
  margin: 0px 15px 0px 15px;
  padding: 0px;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}



/* Sidebar.css */
.sidebar {
  width: 250px; /* Adjusted width for better spacing */
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-color: #000; /* Match the dark background */
  padding: 0;
  margin: 0;
  transition: transform 0.3s ease-in-out;
  z-index: 100; /* Ensure it's above other content */
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
}

.sidebar-footer {
  position: absolute; /* Positions the footer at the bottom of the sidebar */
  bottom: 0; /* Aligns the footer to the bottom */
  width: 100%; /* Ensures the footer spans the width of the sidebar */
  text-align: center; /* Centers the text in the footer */
  padding: 0px;
  margin: 20px 0px;
  box-sizing: border-box; /* Ensures padding is included in width */
}

.main-content {
  transition: margin-left 0.3s ease-in-out;
}

.sidebar-visible {
  margin-left: 250px; /* Match the width of the sidebar */
}

.sidebar.hidden {
  transform: translateX(-100%);
}

.sidebar.show {
  transform: translateX(0);
}

.sidebar a {
  color: #fff; /* White text */
  display: flex;
  align-items: center;
  padding: 10px 8px; /* Adjust padding as needed */
  margin: 4px 10px; /* Adjust margin as needed */
  text-decoration: none;
  font-size: 16px; /* Adjust font size as needed */
  font-weight: 500; /* Bold font */
  border-radius: 8px; /* Rounded corners for the links */
  transition: background-color 0.3s, color 0.3s; /* Smooth transition for hover and active states */
}

.sidebar a:hover, .sidebar a.active {
  background-color: rgba(32, 33, 35, 1);
}

.toggle-button {
  position: fixed;
  left: 10px; /* Adjust position to match design */
  top: 10px;
  background: none;
  border: none;
  color: #000;
  font-size: 24px; /* Larger touch area for mobile */
  z-index: 110; /* Above the sidebar */
}

.sidebar-sign-out {
  background: none;
  border: none;
  color: #fff; /* White text */
  width: calc(100% - 20px);;
  display: flex;
  align-items: center;
  padding: 10px 8px; /* Adjust padding as needed */
  margin: 4px 10px; /* Adjust margin as needed */
  text-decoration: none;
  font-size: 16px; /* Adjust font size as needed */
  font-weight: 500; /* Bold font */
  border-radius: 8px; /* Rounded corners for the links */
  transition: background-color 0.3s, color 0.3s; /* Smooth transition for hover and active states */
  cursor: pointer; /* Changes the mouse cursor to a pointer */
  box-sizing: border-box; /* Includes padding and border in the width */
  justify-content: center; /* Center the text horizontally */
  background-color: rgba(32, 33, 35, 1);
}

.sidebar-sign-out:hover {
  background-color: red; /* Same as the hover effect for links */
}

.sidebar-overlay {
  display: none; 
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  z-index: 99; /* Right below the sidebar */
}

@media (max-width: 768px) {
  .sidebar {
    transform: translateX(-100%); /* Start off-screen */
    z-index: 100;
  }
  
  .toggle-button {
    display: block; /* Only show button on mobile */
  }
  .sidebar-visible {
    margin-left: 0; /* No margin on mobile since sidebar is full width */
  }
  .sidebar.show {
    display: block; /* Show overlay when sidebar is open */
  }
  .sidebar-overlay {
    display: block; /* Show overlay when sidebar is open */
  }
  
}

/* Desktop view adjustments */
@media (min-width: 769px) {
  .sidebar {
    transform: translateX(0); /* Always visible on desktop */
  }
 
}

/* Popup.css */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4); /* Slightly lighter overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  transition: background-color 0.3s ease; /* Smooth transition for background */
}

.popup-content {
  background: #fff;
  padding: 20px 30px; /* Increased horizontal padding */
  border-radius: 12px; /* More pronounced rounded corners */
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); /* Softer shadow */
  transition: transform 0.3s ease-out; /* Smooth transition for popup */
  transform: scale(0.95); /* Start slightly scaled down */
}

.popup-content:hover {
  transform: scale(1); /* Scale up on hover for a subtle interaction */
}

.popup-close-button, .popup-action-button {
  background: none;
  color: #007aff; /* Apple's typical blue */
  padding: 10px 20px;
  margin-top: 10px;
  margin-right: 10px; /* Spacing between buttons */
  border-radius: 12px; /* Rounded corners */
  cursor: pointer;
  border: 0;
  transition: background-color 0.3s, color 0.3s; /* Smooth transition for hover effects */
}

.popup-action-button {
  background: #007aff;
  color: white; /* Apple's typical blue */
  padding: 10px 20px;
  margin-top: 10px;
  margin-right: 10px; /* Spacing between buttons */
  border-radius: 12px; /* Rounded corners */
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s; /* Smooth transition for hover effects */
}

.popup-close-button:hover, .popup-action-button:hover {
  background-color: #007aff;
  color: white; /* Invert colors on hover */
}

.popup-close-button {
  border-color: #ff3b30; /* Red color for the cancel/close button */
  color: #ff3b30;
}

.popup-close-button:hover {
  background-color: #ff3b30;
}

/* DashboardOverview.css */
.dashboard-overview {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  padding: 20px;
}

.dashboard-section {
  background-color: #f4f4f4;
  border-radius: 8px;
  padding: 20px;
}

/* Add to DashboardOverview.css */
.dashboard-statistics {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 10px;
}

.statistic-item {
  background-color: #fff;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
}

.statistic-title {
  font-size: 14px;
  color: #666;
}

.statistic-value {
  font-size: 18px;
  font-weight: bold;
  color: #333;
  margin-top: 5px;
  display: block;
}

.sidebar-category {
  font-size: 14px;
  font-weight: 500;
  color: #666;
  margin-left: 20px;
  margin-top: 20px;
  margin-bottom: 10px;
}
/* ADD BIKE FORM */
.add-bike-form {
  background-color: #fff;
  padding: 20px;
  max-width: 480px;
  margin: 0;
}

.add-bike-form h1 {
  color: var(--selected-color);
  text-align: center;
}

.add-bike-form label {
  font-size: 14px;
  font-weight: 500;
  color: #666;
}

.add-bike-form input[type="text"] {
  width: 100%;
  font-size: 16px;
  padding: 12px 10px;
  border-radius: 8px;
  background-color: #f0f0f0;
  border: 0px solid #ddd;
  box-sizing: border-box;
}

/* Add this to ensure responsiveness */
@media (max-width: 768px) {
  .add-bike-form {
    width: 90%;
    margin: 20px auto;
  }
}

.styledPicker {
  width: 100%;
  font-size: 16px;
  padding: 12px 10px;
  margin: 0;
  border-radius: 8px;
  background-color: #f0f0f0;
  border: 0px solid #ddd;
  box-sizing: border-box;
}

/* BIKES */
.bike-card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 15px;
  margin: 10px;
  text-align: left;
}

.bike-card h3 {
  color: var(--selected-color);
  margin-bottom: 10px;
}

.bike-card p {
  color: #333;
  margin-bottom: 5px;
}
